import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './generic-page.module.scss';

interface IProps {
  data: {
    directusGenericPage: {
      page_title: string;
      page_description: string;
      title: string;
      description: string;
      content: any;
    };
  };
}

export const data = graphql`
  query($path: String!) {
    directusGenericPage(path: { eq: $path }) {
      page_title
      page_description
      title
      description
      content
    }
  }
`;

const GenericPage: React.FC<IProps> = ({
  data: {
    directusGenericPage: { page_title, page_description, title, description, content },
  },
}) => {
  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contentHeader}>
              <h1>{title}</h1>
              <hr />
              {description && <p className={styles.description}>{description}</p>}
            </div>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GenericPage;
